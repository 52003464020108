import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./quasar";

Vue.config.productionTip = false;

// your custom code
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
Vue.prototype.$http = axios;
Vue.prototype.$isLogin = false;

Vue.prototype.$ACTIVE_TAHUN_AJAR = {
  SMP_PA: "-",
  SMP_PI: "-",
  MA: "-",
};
Vue.directive("can", {
  bind: (el, binding, vnode) => {
    // vuex diisi saat sukses login
    let store = vnode.context.$store;
    // hak akses di bank data: STAFF, YAYASAN, ADMIN
    if (binding.value.includes("ADMIN") && store.state.is_admin) {
      return;
    } else if (binding.value.includes("YAYASAN") && store.state.is_yayasan) {
      return;
    } else if (binding.value.includes("AKADEMIK") && store.state.is_akademik) {
      return;
    } else if (
      binding.value.includes("PENGASUHAN") &&
      store.state.is_pengasuhan
    ) {
      return;
    } else {
      vnode.elm.style.display = "none";
    }
  },
});

import helpers from "@/helpers";
Vue.use(helpers);

import XLSX from "xlsx";
Vue.prototype.$xlsx = XLSX;

import VueGraph from "vue-graph";
Vue.use(VueGraph);

import Vue2Filters from "vue2-filters";
Vue.use(Vue2Filters);

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
// end of your custom code

Vue.prototype.$LIST_JENJANG = ["SMP PA", "SMP PI", "MA"];

Vue.prototype.$ROLE_JENJANG = "";

Vue.prototype.$SET_JENJANG = (jenjang) => {
  if (jenjang != "ADMIN") {
    Vue.prototype.$LIST_JENJANG = [`${jenjang}`];
    if (jenjang == "MA") {
      Vue.prototype.$LIST_PENGASUHAN = ["PENGASUHAN PUTRA", "PENGASUHAN PUTRI"];
    } else if (jenjang == "SMP PA") {
      Vue.prototype.$LIST_PENGASUHAN = ["PENGASUHAN PUTRA"];
    } else if (jenjang == "SMP PI") {
      Vue.prototype.$LIST_PENGASUHAN = ["PENGASUHAN PUTRI"];
    }
  } else {
    Vue.prototype.$LIST_JENJANG = ["SMP PA", "SMP PI", "MA"];
    Vue.prototype.$LIST_PENGASUHAN = ["PENGASUHAN PUTRA", "PENGASUHAN PUTRI"];
  }
};

Vue.prototype.$LIST_PENGASUHAN = ["PENGASUHAN PUTRA", "PENGASUHAN PUTRI"];

Vue.use(require("vue-moment"));

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
