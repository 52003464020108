<template>
  <q-layout view="hHh lpR fFf">
    <q-header bordered class="bg-primary text-white" v-if="!$isLogin">
      <q-toolbar>
        <q-btn
          v-show="!$isHome"
          dense
          flat
          icon="home"
          @click="backHome"
        ></q-btn>
        <q-img
          src="./img/Logo Yayasan Horizontal.png"
          contain
          class="bg-white"
          :ratio="13 / 6"
          style="height:50px; max-width:100px"
        ></q-img>
        <q-toolbar-title>Yayasan Ar-Risalah</q-toolbar-title>

        <div class="row justify-between">
          <q-btn-dropdown flat no-caps :label="user.nama">
            <q-list>
              <q-item
                clickable
                v-close-popup
                @click="$router.push('/pengaturanedituser')"
              >
                <q-item-section>
                  <q-item-label class="text-bold">Profile</q-item-label>
                </q-item-section>
              </q-item>

              <q-item
                clickable
                class="text-negative"
                v-close-popup
                @click="logout"
              >
                <q-item-section>
                  <q-item-label class="text-bold">Logout</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </div>
      </q-toolbar>
      <q-bar class="row justify-between">
        <a class="text-overline">{{ $route.name }}</a>
        <div
          v-if="
            $route.name != 'List Tahun Ajaran' &&
              $route.name != 'Tambah Tahun Ajaran' &&
              this.$q.screen.gt.sm
          "
          class="row justify-end q-gutter-md items-center"
        >
          <a>
            <span>Tahun Ajaran Berlangsung :</span>
          </a>
          <a v-if="user.jenjang == 'SMP PA' || user.jenjang == 'ADMIN'">
            <span>SMP PA :</span>
            <span>
              <i>{{ tahunAjar.SMP_PA }}</i>
            </span>
          </a>
          <a v-if="user.jenjang == 'SMP PI' || user.jenjang == 'ADMIN'">
            <span>SMP PI :</span>
            <span>
              <i>{{ tahunAjar.SMP_PI }}</i>
            </span>
          </a>
          <a v-if="user.jenjang == 'MA' || user.jenjang == 'ADMIN'">
            <span>MA :</span>
            <span>
              <i>{{ tahunAjar.MA }}</i>
            </span>
          </a>
          <q-btn
            :to="'/pengaturanlisttahunajaran/' + $LIST_JENJANG[0]"
            stretch
            unelevated
            color="negative"
            label="Ganti"
          ></q-btn>
        </div>
      </q-bar>
    </q-header>

    <q-header
      bordered
      class="bg-primary text-white"
      v-if="!$isLogin && isSmallScreen"
    >
      <q-toolbar>
        <q-btn
          v-show="!$isHome"
          dense
          flat
          icon="home"
          @click="backHome"
        ></q-btn>
        <q-img
          src="./img/Logo Yayasan Horizontal.png"
          contain
          class="bg-white"
          :ratio="13 / 6"
          style="height:50px; max-width:100px"
        ></q-img>
        <q-toolbar-title>Bank Data</q-toolbar-title>
        <q-btn flat dense icon="account_circle">
          <q-menu anchor="top left">
            <q-list>
              <q-item class="text-bold text-uppercase q-pb-none">
                <q-item-section>{{ user.nama }}</q-item-section>
              </q-item>
              <q-separator></q-separator>
              <q-item class="q-mt-sm">
                <q-item-section>
                  <q-item-label overline>Tahun Ajaran Berjalan :</q-item-label>
                  <q-markup-table separator="none" dense flat class="caption">
                    <tbody>
                      <tr
                        v-if="
                          user.jenjang == 'SMP PA' || user.jenjang == 'ADMIN'
                        "
                      >
                        <td>SMP PA</td>
                        <td>:</td>
                        <td>
                          <i>{{ tahunAjar.SMP_PA }}</i>
                        </td>
                      </tr>
                      <tr
                        v-if="
                          user.jenjang == 'SMP PI' || user.jenjang == 'ADMIN'
                        "
                      >
                        <td>SMP PI</td>
                        <td>:</td>
                        <td>
                          <i>{{ tahunAjar.SMP_PI }}</i>
                        </td>
                      </tr>
                      <tr
                        v-if="user.jenjang == 'MA' || user.jenjang == 'ADMIN'"
                      >
                        <td>MA</td>
                        <td>:</td>
                        <td>
                          <i>{{ tahunAjar.MA }}</i>
                        </td>
                      </tr>
                    </tbody>
                  </q-markup-table>
                </q-item-section>
              </q-item>
              <q-separator></q-separator>
              <q-item>
                <q-item-section>
                  <q-item-label class="row justify-between">
                    <q-btn flat color="positive" to="/pengaturanedituser"
                      >Profile</q-btn
                    >
                    <q-btn flat color="negative" @click="logout">logout</q-btn>
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </q-toolbar>

      <q-bar class="row justify-between">
        <a class="text-overline">{{ $route.name }}</a>
      </q-bar>
    </q-header>

    <q-page-container>
      <router-view @hasLogin="checkLogin" v-if="isReady || toLogin" />
    </q-page-container>
  </q-layout>
</template>

<script>
import General from "@/mixins/General";
import { mapMutations, mapActions, mapGetters } from "vuex";
import YourLogo from "./assets/splash.png";
export default {
  mixins: [General],
  data() {
    return {
      toLogin: false,
      isReady: false,
      tahunAjar: {
        SMP_PA: "-",
        SMP_PI: "-",
        MA: "-",
      },
      user: {
        nama: "",
      },
    };
  },
  async created() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    await this.checkLogin();
    await this.getTahunAjar();
    this.$q.loading.hide();
  },
  computed: {
    isSmallScreen() {
      return this.$q.screen.lt.md;
    },
    logo() {
      return YourLogo;
    },
  },
  watch: {
    $route: function(to, from) {
      if (to.name == "Home" && from.name == "List Tahun Ajaran") {
        this.getTahunAjar();
      }
      if (to.name == "Login") {
        this.toLogin = true;
      }
    },
  },
  methods: {
    ...mapMutations(["setRole", "clearState"]),
    ...mapActions(["sendCurrentUser"]),
    async getTahunAjar() {
      let resp = await this.$http.get(`/pengaturan/tahun_ajaran/getactive`);
      let active = resp.data;

      this.tahunAjar = {
        SMP_PA: "-",
        SMP_PI: "-",
        MA: "-",
      };

      for (let item of active) {
        if (item.is_active == 1) {
          if (item.jenjang == "SMP PA") {
            this.tahunAjar.SMP_PA = `${item.nama} (${item.semester})`;
          } else if (item.jenjang == "SMP PI") {
            this.tahunAjar.SMP_PI = `${item.nama} (${item.semester})`;
          } else if (item.jenjang == "MA") {
            this.tahunAjar.MA = `${item.nama} (${item.semester})`;
          }
        }
      }
      this.$ACTIVE_TAHUN_AJAR = JSON.parse(JSON.stringify(this.tahunAjar));
    },
    async checkLogin() {
      let token = localStorage.getItem("token");
      if (token != null) {
        try {
          let resp = await this.$http.get("/user_data", this.MGetHeader());
          this.user = resp.data;
          let data = {
            is_admin: false,
            is_yayasan: false,
            is_akademik: false,
            is_pengasuhan: false,
          };
          if (resp.data.role == "administrator") {
            data.is_admin = true;
          } else if (resp.data.role == "pengasuhan") {
            data.is_pengasuhan = true;
          } else if (resp.data.role == "akademik") {
            data.is_akademik = true;
          } else if (resp.data.role == "yayasan") {
            data.is_yayasan = true;
          }
          await this.setRole(data);
          await this.sendCurrentUser();
          this.$ROLE_JENJANG = resp.data.jenjang;
          await this.$SET_JENJANG(this.$ROLE_JENJANG);
          this.isReady = true;
        } catch (err) {
          this.logout();
        }
      }
    },
    async login() {},
    async logout() {
      localStorage.clear();
      this.MNotifyPositive("proses logout berhasil");
      await this.clearState();
      this.$router.push("/login");
    },
    backHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/quasar.scss";
.stickyTable {
  height: 300px;

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: $indigo-5;
  }
  thead tr th {
    position: sticky;
    z-index: 1;
  }
  thead tr:first-child th {
    top: 0;
  }
  &.q-table--loading thead tr:last-child th {
    top: 48px;
  }
}
</style>
