import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    is_admin: false,
    is_yayasan: false,
    is_pengasuhan: false,
    is_akademik: false,
    user:{}
  },
  mutations: {
    setRole(state, payload) {
      state.is_admin = payload.is_admin
      state.is_yayasan = payload.is_yayasan
      state.is_pengasuhan = payload.is_pengasuhan
      state.is_akademik = payload.is_akademik
    },
    setUser(state,data){
      state.user = data
    },
    clearState(state){
      state.is_admin= false;
      state.is_yayasan= false;
      state.is_pengasuhan= false;
      state.is_akademik= false;
      state.user={};
    }
  },
  actions:{
    async sendCurrentUser(state){
      var header = {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") }
      }
      let resp = await Vue.prototype.$http.get("/user_data",header)
      state.commit("setUser",resp.data)
    }
  },
  modules: {
  },
  getters:{
    async getCurrentUser(state){
      return state.user
    }
  }
})
