var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-layout',{attrs:{"view":"hHh lpR fFf"}},[(!_vm.$isLogin)?_c('q-header',{staticClass:"bg-primary text-white",attrs:{"bordered":""}},[_c('q-toolbar',[_c('q-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$isHome),expression:"!$isHome"}],attrs:{"dense":"","flat":"","icon":"home"},on:{"click":_vm.backHome}}),_c('q-img',{staticClass:"bg-white",staticStyle:{"height":"50px","max-width":"100px"},attrs:{"src":"./img/Logo Yayasan Horizontal.png","contain":"","ratio":13 / 6}}),_c('q-toolbar-title',[_vm._v("Yayasan Ar-Risalah")]),_c('div',{staticClass:"row justify-between"},[_c('q-btn-dropdown',{attrs:{"flat":"","no-caps":"","label":_vm.user.nama}},[_c('q-list',[_c('q-item',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"clickable":""},on:{"click":function($event){return _vm.$router.push('/pengaturanedituser')}}},[_c('q-item-section',[_c('q-item-label',{staticClass:"text-bold"},[_vm._v("Profile")])],1)],1),_c('q-item',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"text-negative",attrs:{"clickable":""},on:{"click":_vm.logout}},[_c('q-item-section',[_c('q-item-label',{staticClass:"text-bold"},[_vm._v("Logout")])],1)],1)],1)],1)],1)],1),_c('q-bar',{staticClass:"row justify-between"},[_c('a',{staticClass:"text-overline"},[_vm._v(_vm._s(_vm.$route.name))]),(
          _vm.$route.name != 'List Tahun Ajaran' &&
            _vm.$route.name != 'Tambah Tahun Ajaran' &&
            this.$q.screen.gt.sm
        )?_c('div',{staticClass:"row justify-end q-gutter-md items-center"},[_c('a',[_c('span',[_vm._v("Tahun Ajaran Berlangsung :")])]),(_vm.user.jenjang == 'SMP PA' || _vm.user.jenjang == 'ADMIN')?_c('a',[_c('span',[_vm._v("SMP PA :")]),_c('span',[_c('i',[_vm._v(_vm._s(_vm.tahunAjar.SMP_PA))])])]):_vm._e(),(_vm.user.jenjang == 'SMP PI' || _vm.user.jenjang == 'ADMIN')?_c('a',[_c('span',[_vm._v("SMP PI :")]),_c('span',[_c('i',[_vm._v(_vm._s(_vm.tahunAjar.SMP_PI))])])]):_vm._e(),(_vm.user.jenjang == 'MA' || _vm.user.jenjang == 'ADMIN')?_c('a',[_c('span',[_vm._v("MA :")]),_c('span',[_c('i',[_vm._v(_vm._s(_vm.tahunAjar.MA))])])]):_vm._e(),_c('q-btn',{attrs:{"to":'/pengaturanlisttahunajaran/' + _vm.$LIST_JENJANG[0],"stretch":"","unelevated":"","color":"negative","label":"Ganti"}})],1):_vm._e()])],1):_vm._e(),(!_vm.$isLogin && _vm.isSmallScreen)?_c('q-header',{staticClass:"bg-primary text-white",attrs:{"bordered":""}},[_c('q-toolbar',[_c('q-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$isHome),expression:"!$isHome"}],attrs:{"dense":"","flat":"","icon":"home"},on:{"click":_vm.backHome}}),_c('q-img',{staticClass:"bg-white",staticStyle:{"height":"50px","max-width":"100px"},attrs:{"src":"./img/Logo Yayasan Horizontal.png","contain":"","ratio":13 / 6}}),_c('q-toolbar-title',[_vm._v("Bank Data")]),_c('q-btn',{attrs:{"flat":"","dense":"","icon":"account_circle"}},[_c('q-menu',{attrs:{"anchor":"top left"}},[_c('q-list',[_c('q-item',{staticClass:"text-bold text-uppercase q-pb-none"},[_c('q-item-section',[_vm._v(_vm._s(_vm.user.nama))])],1),_c('q-separator'),_c('q-item',{staticClass:"q-mt-sm"},[_c('q-item-section',[_c('q-item-label',{attrs:{"overline":""}},[_vm._v("Tahun Ajaran Berjalan :")]),_c('q-markup-table',{staticClass:"caption",attrs:{"separator":"none","dense":"","flat":""}},[_c('tbody',[(
                        _vm.user.jenjang == 'SMP PA' || _vm.user.jenjang == 'ADMIN'
                      )?_c('tr',[_c('td',[_vm._v("SMP PA")]),_c('td',[_vm._v(":")]),_c('td',[_c('i',[_vm._v(_vm._s(_vm.tahunAjar.SMP_PA))])])]):_vm._e(),(
                        _vm.user.jenjang == 'SMP PI' || _vm.user.jenjang == 'ADMIN'
                      )?_c('tr',[_c('td',[_vm._v("SMP PI")]),_c('td',[_vm._v(":")]),_c('td',[_c('i',[_vm._v(_vm._s(_vm.tahunAjar.SMP_PI))])])]):_vm._e(),(_vm.user.jenjang == 'MA' || _vm.user.jenjang == 'ADMIN')?_c('tr',[_c('td',[_vm._v("MA")]),_c('td',[_vm._v(":")]),_c('td',[_c('i',[_vm._v(_vm._s(_vm.tahunAjar.MA))])])]):_vm._e()])])],1)],1),_c('q-separator'),_c('q-item',[_c('q-item-section',[_c('q-item-label',{staticClass:"row justify-between"},[_c('q-btn',{attrs:{"flat":"","color":"positive","to":"/pengaturanedituser"}},[_vm._v("Profile")]),_c('q-btn',{attrs:{"flat":"","color":"negative"},on:{"click":_vm.logout}},[_vm._v("logout")])],1)],1)],1)],1)],1)],1)],1),_c('q-bar',{staticClass:"row justify-between"},[_c('a',{staticClass:"text-overline"},[_vm._v(_vm._s(_vm.$route.name))])])],1):_vm._e(),_c('q-page-container',[(_vm.isReady || _vm.toLogin)?_c('router-view',{on:{"hasLogin":_vm.checkLogin}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }