import XLSX from 'xlsx'

const copyObj = (obj) => {
  obj = JSON.stringify(obj)
  obj = JSON.parse(obj)
  return obj
}
const plusOne = (val) => {
  return val + 1
}
const getHeader = () => {
  var header = {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") }
  }
  return header
}

const ReadXLSX = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = (e) => {
      let json = []
      var bin = ""
      var bytes = new Uint8Array(e.target.result)
      var len = bytes.byteLength

      for (var i = 0; i < len; i++) {
        bin += String.fromCharCode(bytes[i])
      }

      var wb = XLSX.read(bin, { type: 'binary' })
      var name = wb.SheetNames[0]
      var ws = wb.Sheets[name]
      json = XLSX.utils.sheet_to_csv(ws, { FS: ';' })
      resolve(json)
    }
    if (!file) return
    reader.readAsArrayBuffer(file)
  })
}

const ReadXLSXperSheet = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = (e) => {
      let json = []
      var bin = ""
      var bytes = new Uint8Array(e.target.result)
      var len = bytes.byteLength

      for (var i = 0; i < len; i++) {
        bin += String.fromCharCode(bytes[i])
      }
      let bundle_data=[];
      var wb = XLSX.read(bin, { type: 'binary' })
      var wb_len = wb.SheetNames.length
      for(var i =0; i < wb_len; i++){
        var name = wb.SheetNames[i]
        var ws = wb.Sheets[name]
        bundle_data.push(XLSX.utils.sheet_to_csv(ws, { FS: ';' })) 

      }
      
      resolve(bundle_data)
    }
    if (!file) return
    reader.readAsArrayBuffer(file)
  })
}
export default {
  copyObj,
  plusOne,
  getHeader,
  ReadXLSX,
  ReadXLSXperSheet
}