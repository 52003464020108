import '@/quasar'
export default {
  methods: {
    MCopyObject(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    MNotifyPositive(msg) {
      this.$q.notify({
        type: "positive",
        message: msg
      })
    },
    MNotifyNegative(msg) {
      this.$q.notify({
        type: "negative",
        message: msg
      })
    },
    MGetHeader() {
      let header = {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") }
      }
      return header
    },

  }
}