import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/pengaturanlisttahunajaran/:jenjang",
    name: "List Tahun Ajaran",
    component: () => import("../views/PengaturanListTahunAjaran"),
  },
  {
    path: "/pengaturantambahtahunajaran/:id/:jenjang",
    name: "Tambah Tahun Ajaran",
    component: () => import("../views/PengaturanTambahTahunAjaran"),
  },
  {
    path: "/pengaturanharilibur/:id",
    name: "Hari Libur",
    component: () => import("../views/PengaturanHariLibur"),
  },
  {
    path: "/pengaturanedituser",
    name: "Edit User",
    component: () => import("../views/PengaturanEditUser"),
  },
  {
    path: "/pengaturaneditpassword",
    name: "Edit Password",
    component: () => import("../views/PengaturanEditPassword"),
  },
  // siswa area
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login"),
  },
  {
    path: "/importexport",
    name: "Import Export",
    component: () => import("../views/ImportExport"),
  },
  {
    path: "/siswaimportdata",
    name: "Import Data Siswa",
    component: () => import("../views/SiswaImportData"),
  },
  {
    path: "/siswalist/:jenjang",
    name: "List Siswa",
    component: () => import("../views/SiswaList"),
  },
  {
    path: "/siswaeditdata/:id/:jenjang",
    name: "Edit Data Siwa",
    component: () => import("../views/SiswaEditData"),
  },
  {
    path: "/siswainputbeasiswa",
    name: "List Beasiswa",
    component: () => import("../views/SiswaBeasiswa"),
  },
  {
    path: "/siswanonaktif/:jenjang/:tingkat",
    name: "Siswa Tidak Aktif",
    component: () => import("../views/SiswaNonaktif"),
  },
  //end siswa

  //akademik area
  {
    path: "/akademiklistguru/:jenjang",
    name: "List Guru",
    component: () => import("../views/AkademikListGuru"),
  },
  {
    path: "/akademikeditguru/:id/:selJenjang",
    name: "Data Guru",
    component: () => import("../views/AkademikEditGuru"),
  },
  {
    path: "/akademiklistkelas",
    name: "List Kelas",
    component: () => import("../views/AkademikListKelas"),
  },
  {
    path: "/akademikisikelas",
    name: "Isi Kelas",
    component: () => import("../views/AkademikIsiKelas"),
  },
  {
    path: "/akademikriwayatraport",
    name: "Riwayat Raport",
    component: () => import("../views/AkademikRiwayatRaport"),
  },
  {
    path: "/akademikraportsiswa/:id",
    name: "Raport Siswa",
    component: () => import("../views/AkademikRaportSiswa"),
  },
  //end akademik

  //pengasuhan area
  {
    path: "/pengasuhanlistmusyrif/:jenjang",
    name: "List Pembina",
    component: () => import("../views/PengasuhanListMusyrif"),
  },
  {
    path: "/pengasuhaneditmusyrif/:id/:selJenjang",
    name: "Data Pembina",
    component: () => import("../views/PengasuhanEditMusyrif"),
  },
  {
    path: "/pengasuhanlistmudabbir/:id_asrama",
    name: "List Mudabbir",
    component: () => import("../views/PengasuhanListMudabbir"),
  },
  {
    path: "/pengasuhaneditmudabbir/:id_asrama",
    name: "Data Mudabbir",
    component: () => import("../views/PengasuhanEditMudabbir"),
  },
  {
    path: "/pengasuhanlistasrama",
    name: "List Asrama",
    component: () => import("../views/PengasuhanListAsrama"),
  },
  {
    path: "/pengasuhanlistkamar",
    name: "List Kamar",
    component: () => import("../views/PengasuhanListKamar"),
  },
  {
    path: "/pengasuhanisikamar",
    name: "Isi Kamar",
    component: () => import("../views/PengasuhanIsiKamar"),
  },
  {
    path: "/pengasuhanisimusyrif",
    name: "Isi Pembina",
    component: () => import("../views/PengasuhanIsiMusyrif"),
  },
  //end pengasuhan

  //statistik area
  {
    path: "/statistikdaftarkamar/:id_asrama",
    name: "Daftar Kamar",
    component: () => import("../views/StatistikDaftarKamar"),
  },
  {
    path: "/statistikanggotakamar/:id",
    name: "Anggota Kamar",
    component: () => import("../views/StatistikAnggotaKamar"),
  },
  {
    path: "/statistikgrafikpertumbuhan",
    name: "Grafik Pertumbuhan",
    component: () => import("../views/StatistikGrafikPertumbuhan"),
  },
  {
    path: "/statistiksiswa/:jenjang",
    name: "Statistik Siswa",
    component: () => import("../views/StatistikSiswa"),
  },
  {
    path: "/statistiksiswalistasal/:jenjang/:sekolah_asal",
    name: "Statistik Asal Siswa",
    component: () => import("../views/StatistikSiswaListAsal"),
  },
  {
    path: "/statistikstatussiswa/:jenjang/:id_ta",
    name: "Statistik Pergerakan Siswa",
    component: () => import("../views/StatistikStatusSiswa"),
  },
  {
    path: "/statistikorangtua",
    name: "Statistik Orang Tua",
    component: () => import("../views/StatistikOrangTua"),
  },
  {
    path: "/statistikprestasi",
    name: "Statistik Prestasi",
    component: () => import("../views/StatistikPrestasi"),
  },
  {
    path: "/statistiksebaranalumni",
    name: "Sebaran Alumni",
    component: () => import("../views/StatistikSebaranAlumni"),
  },
  {
    path: "/statistikkehadiransiswa",
    name: "Statistik Kehadiran",
    component: () => import("../views/StatistikKehadiranSiswa"),
  },

  //end statistik
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  var toLogin = to.name == "Login";
  var isUser = localStorage.getItem("token") != null;
  var toHome = to.name == "Home";

  // hide header if not login page
  if (toLogin) {
    Vue.prototype.$isLogin = true;
  } else {
    Vue.prototype.$isLogin = false;
  }

  if (toHome) {
    Vue.prototype.$isHome = true;
  } else {
    Vue.prototype.$isHome = false;
  }

  // router guards
  if (!toLogin && !isUser) {
    next("/login");
  } else if (!toLogin && isUser) {
    next();
  } else if (toLogin && isUser) {
    next("/");
  } else if (toLogin && !isUser) {
    next();
  }
});

export default router;
